import React from 'react';
import { BackgroundVideo } from '../media/backgroundVideo';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import AnchorScrollLink from '../links/anchorScrollLink';
import SubscriptionButton from '../links/subscriptionButton';
import { motion } from 'framer-motion';

const Season2025Banner = () => {
  const breakpoints = useBreakpoint();

  return (
    <div className='h-screen min-h-[700px] w-full bg-white relative overflow-hidden text-grey pt-[100px] season-text'>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full scale-105 bg-white  '>
        {breakpoints.sm ? (
          <BackgroundVideo
            video='https://player.vimeo.com/progressive_redirect/playback/1011198976/rendition/720p/file.mp4?loc=external&log_user=0&signature=4346a6ef055e269aa4796cba04f0c481ba22d22532e79a1dbfb7a706e9a533eb'
            full
            className={'bg-white'}
          />
        ) : (
          <video
            src='https://player.vimeo.com/progressive_redirect/playback/1011198986/rendition/720p/file.mp4?loc=external&log_user=0&signature=452478ce5711e410db8582960bba60e9aa3596b4e2940f134af72e79f55bcff8'
            autoPlay
            muted
            loop
            playsInline
            className={'w-full h-full object-cover bg-white'}
          />
        )}
      </div>
      <div className='w-full h-full flex items-center px-gutter relative z-10'>
        <div className='flex flex-col w-full justify-between h-full pt-[23px] lg:pt-0 lg:justify-center'>
          <motion.div
            className='self-end'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}>
            <h2 className='text-[44px] lg:text-[80px]'>
              <span className='font-epicene-italic'>Te</span> Pūngao
              <br />
              <span className='pl-0 lg:pl-44'>Oro</span>
            </h2>
          </motion.div>
          <motion.div
            className='flex flex-col gap-y-[11px] lg:gap-[19px] w-full pb-[23px] lg:pb-0'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}>
            <div className='flex flex-col gap-y-[9px] lg:gap-[20px]'>
              <h1 className='text-[44px] lg:text-[80px]'>
                The Energy
                <br />
                <span className='font-epicene-italic pr-2 lg:pr-3'>
                  of{' '}
                </span>{' '}
                Sound
              </h1>
              <p className='text-[20px] lg:text-[26px] leading-[1.4]'>
                Welcome to 2025 Season
              </p>
            </div>

            <div className='flex flex-col gap-y-[8px] lg:gap-[12px]'>
              <AnchorScrollLink
                target={{
                  current: 'explore-season',
                }}
                linkText='Explore the Season'
                className='btn-standard bg-grey lg:!w-[220px] text-center'
              />
              <SubscriptionButton
                linkText='Book Season'
                className='btn-standard bg-seasonRed text-seasonRed lg:!w-[220px] text-center'
              />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Season2025Banner;
