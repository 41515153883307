import React, { useEffect } from 'react';
import SubscriptionButton from '../links/subscriptionButton';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const FullImageText = () => {
  const breakpoints = useBreakpoint();
  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  useEffect(() => {
    if (inView) {
      document.documentElement.style.setProperty('--seasonBg', '#F7722C');
      document.documentElement.style.setProperty('--seasonText', '#ffffff');
    }
  }, [inView]);

  return (
    <div ref={ref} className='relative h-full w-full text-seasonWhite z-20'>
      {breakpoints.sm ? (
        <motion.img
          src='/images/season/orange-background.jpg'
          alt='Orange background'
          className='w-full h-full object-cover'
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        />
      ) : (
        <motion.img
          src='/images/season/orange-background-mobile.jpg'
          alt='Orange background mobile'
          className='w-full h-full object-cover'
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        />
      )}
      <div className='absolute bottom-0 left-0 lg:left-[100px] w-full h-fit space-y-[24px] lg:space-y-[40px] px-gutter lg:px-0 pb-[68px] lg:pb-[200px]'>
        <h3 className='text-[56px] lg:text-[140px] leading-[1] font-epicene'>
          Into the
          <br />
          <span className='font-epicene-italic'>world of light</span>
        </h3>
        <p className='blockH8 text-white max-w-[700px]'>
          The CMNZ Series 2025 season explores some of the world’s most
          beautiful musical works, reflecting the joy, pain, and journey of
          life. The series combines cornerstone pieces with new compositions,
          creative partnerships, and multi-artform collaborations, featuring
          both international and New Zealand artists.
        </p>
        <SubscriptionButton
          linkText='Book Season'
          className='btn-standard bg-white text-white hover:!text-seasonRedAlt'
        />
      </div>
    </div>
  );
};

export default FullImageText;
