import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useTracking } from '../components/analytics';
import Season2025Banner from '../components/season/season2025Banner';
import PoemBlock from '../components/season/poemBlock';
import FullImageText from '../components/season/fullImageText';
// Components
import { PageMeta } from '@components/pageMeta';
import ModuleZone from '~moduleZone';
import Layout from '@components/layout';

const TicketingPageTemplate = ({ data: { page } }) => {
  const { pageModules, seo, config } = page || {};
  const { track } = useTracking();

  useEffect(() => {
    try {
      var klaviyo = klaviyo || [];
      var page = window.location.href;
      klaviyo.push([
        'track',
        'Viewed Page',
        { url: 'https://www.chambermusic.co.nz/ticketing' },
      ]);
    } catch (err) {
      console.log('Error occurred adding klaviyo script: ' + err);
    }
  }, []);

  useEffect(() => {
    track('Viewed Page', {
      behaviour: true,
    });
  }, []);

  return (
    <Layout config={config} ticketingPage={true}>
      <PageMeta {...seo} />
      <div className='bg-white relative'>
        <Season2025Banner />
        <div
          className='relative bg-seasonSectionBg text-seasonText transition-all ease-in-out duration-1000'
          id='explore-season'>
          <PoemBlock />
          <FullImageText />
        </div>
      </div>
      {pageModules && <ModuleZone {...pageModules} isTicketingPage />}
    </Layout>
  );
};

export default TicketingPageTemplate;

export const pageQuery = graphql`
  query ticketingPage {
    page: sanityTicketingPage {
      seo {
        ...SEO
      }
      config: pageConfig {
        reverseHeader
        unpinnedHeader
      }
      pageModules: modules {
        ...RestrictedPageModules
      }
    }
  }
`;
