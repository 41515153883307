import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const PoemSnippet = ({ title, text, className, delay, inView }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
    transition={{ duration: 0.8, delay }}
    className={`flex flex-col gap-y-[8px] lg:gap-y-[12px] w-2/3 lg:w-1/2 ${className}`}>
    <h3 className='blockH9 font-display uppercase'>{title}</h3>
    <div className='text-[20px] lg:text-[24px] season-text !leading-[1.4]'>
      <p>{text}</p>
    </div>
  </motion.div>
);

const PoemBlock = () => {
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      document.documentElement.style.setProperty('--seasonBg', '#FFF');
      document.documentElement.style.setProperty('--seasonText', '#717171');
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className=' relative pt-space-s pb-space-l px-gutter flex flex-col md:flex-row gap-x-20  gap-y-[100px] md:gap-y-0 '>
      <div className='w-full h-full  md:basis-1/2 flex flex-col gap-y-[60px] lg:gap-y-[26px]'>
        <PoemSnippet
          title='TE KORE'
          text={
            <>
              Nō te āio Rangi
              <br />
              Ki <span className='font-epicene-italic'>te āio Nuku</span>
              <br />
              Ka puta ko pūngao oro
            </>
          }
          delay={0.2}
          inView={inView}
        />
        <PoemSnippet
          title='TE PŌ'
          text={
            <>
              He ōrua reretahi
              <br />
              <span className='font-epicene-italic'>He hiringa whakaaro</span>
              <br />
              Aurongo ake te ngākau
            </>
          }
          className='self-end'
          delay={0.4}
          inView={inView}
        />
        <PoemSnippet
          title='TE WHAIAO'
          text={
            <>
              Tōiriiri ana i roto
              <br />
              <span className='font-epicene-italic'>
                Pākurukuru mai i te pō
              </span>
              <br />
              Ki te whaiao
            </>
          }
          delay={0.6}
          inView={inView}
        />
        <PoemSnippet
          title='TE AO MĀRAMA'
          text={
            <>
              Ki te ao mārama
              <br />
              <span className='font-epicene-italic'>Tīhei mauriora</span>
            </>
          }
          className='self-end'
          delay={0.8}
          inView={inView}
        />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
        transition={{ duration: 0.8, delay: 1 }}
        className='md:basis-1/2 blockH7 self-center '>
        <p>
          The 2025 season features some of the most beautiful music composed,
          which captures the journey of life. Inspired by the Māori creation
          narrative, in which all created things evolve through three stages of
          being. Te Kore, where there is infinite potential, yet to be awakened.
          Te Pō, in which life is nurtured and grows. Finally, Te Ao Mārama, in
          which the creation enters into the world of light, to be experienced.
        </p>
      </motion.div>
    </div>
  );
};

export default PoemBlock;
